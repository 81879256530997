import './App.sass';
import Header from './Header.js';
import AboutMe from './AboutMe';
import RecentWork from './RecentWork';
import OtherProjects from './OtherProjects';
import Links from './Links';

function App() {
  return (
    <div>
      <Header />
      <AboutMe />
      <RecentWork />
      <OtherProjects />
      <Links />
    </div>
  );
}

export default App;
