import './global.css';


export default function RecentWork() {
  return (
      <section className="hero is-link is-small">
          <div className="hero-body">
                <p className="title has-text-white">
                    <div className="container has-text-centered">
                        Recent Work
                    </div>
                </p>
                <p className="subtitle has-text-white">
                    <div className="container has-text-centered">
                        Software Engineer @ <a href='https://redfin.com' rel='noreferrer' target='_blank'> Redfin </a>
                    </div>
                    <div className="container has-text-centered">
                        Founding Team Member @ <a href='https://pursu.dev' rel='noreferrer' target='_blank'> Pursu </a>
                    </div>
                    <div className="container has-text-centered">
                        UI Development TA @ <a href='https://umich.edu' rel='noreferrer' target='_blank'> UMich </a>
                    </div>
                    <div className="container has-text-centered">
                        Software Engineer Intern @ <a href='https://salesforce.com' rel='noreferrer' target='_blank'> Salesforce </a>
                    </div>
                    <div className="container has-text-centered">
                        Software Engineer Intern @ <a href='https://relativity.com' rel='noreferrer' target='_blank'> Relativity </a>
                    </div>
                </p>
          </div>
      </section>
  );
}

